import React from 'react'
import styles from './faq.module.sass'
import { faqs } from '../faqs'

import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from 'react-accessible-accordion'

import Layout from '../components/layout'
import SEO from '../components/seo'

const FaqPage = () => (
   <Layout>
      <SEO title="Frequently Asked Questions" />
      <div className={['page', styles.page__new_client].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               FAQs
            </h1>
            {/* <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
            </p> */}
         </div>
         <div className={styles.content}>
            {faqs.map(
               (faq, i) =>
                  faq.isActive && (
                     <Accordion
                        key={i}
                        allowZeroExpanded="true"
                        className={styles.accordion}>
                        <AccordionItem className={styles.accordion__item}>
                           <AccordionItemHeading>
                              <AccordionItemButton
                                 className={styles.accordion__button}>
                                 <span>{faq.question}</span>
                              </AccordionItemButton>
                           </AccordionItemHeading>
                           <AccordionItemPanel
                              className={styles.accordion__panel}>
                              <div className={styles.answer}>
                                 {faq.answer.map((a, i) => (
                                    <p key={i}>{a}</p>
                                 ))}
                              </div>
                           </AccordionItemPanel>
                        </AccordionItem>
                     </Accordion>
                  )
            )}
         </div>
      </div>
   </Layout>
)

export default FaqPage
