export const faqs = [
   {
      id: 1,
      question: 'How does SPC develop investment strategies?',
      answer: [
         "Each client portfolio is tailored to a client’s individual financial situation, goals, risk tolerance, investment and tax objectives. For these reasons, we do not have audited performance histories of our clients' investment strategies. We utilize various investment strategies and may buy, sell or hold some of the same investments for many of our clients but in differing allocations. We also may analyze our clients’ financial, tax and estate documents.",
      ],
      isActive: true,
   },
   {
      id: 2,
      question: 'What type of investments do you use?',
      answer: [
         "While we do not sell proprietary products and are not required to recommend investments from any specific money manager, we utilize a wide variety of investments for our clients and we make recommendations based on each individual’s situation. Our clients' investment objectives may range from capital appreciation to income to tax efficiency, or a blend of all of those. To help achieve these objectives, we utilize a wide range of products including, stocks, bonds, mutual funds, exchange traded funds, money market funds, and many others.",
      ],
      isActive: true,
   },
   {
      id: 3,
      question:
         'How does a fee-based relationship work compared to a traditional commission?',
      answer: [
         'A fee-based portfolio charges a fixed percentage fee based on the value of the account. Our fee is an annualized fee which is charged quarterly and is deducted from the portfolio. Transaction based commissions are not charged on assets in a fee-based account. In a traditional brokerage account, commissions and miscellaneous fees are charged on both purchases and sales of securities.',
      ],
      isActive: true,
   },
   {
      id: 4,
      question: 'How does your financial planning process work?',
      answer: [
         'We start by scheduling an initial fact find meeting. The purpose of this meeting is for us to learn more about you and your unique needs, financial situation, and goals. It is an opportunity for you to learn more about us and to determine if we are a good fit for you. If we jointly decide to move forward, we will begin the process of assembling your financial records, analyzing them, and developing an initial plan. At our next meeting we will present your customized financial plan. We schedule a follow-up meeting within 30 days to evaluate your questions; any changes you may want to make to your plan, and to review the necessary implementation steps. We review the progress with you on a periodic basis. Typically, we meet with our clients 3-4 times in the first year of our relationship and then annually thereafter. However, we are always available to meet should there be a need.',
      ],
      isActive: true,
   },
   {
      id: 5,
      question: 'How often do you meet with clients?',
      answer: [
         'We strive to meet with our current clients at least annually. Many clients find that additional meetings are needed during transition periods of their life such as marriage, death of a family member, birth of a child, job/career change, approaching retirement, or change in personal situation.',
      ],
      isActive: true,
   },
   {
      id: 6,
      question: 'How often are clients’ portfolios reviewed?',
      answer: [
         'We regularly monitor our clients’ investment portfolios and make recommendations or changes when market conditions warrant. Accounts with frequent activity including those with income producing investments, deposits of additional money or investments, and withdrawals may be reviewed more frequently than lesser active accounts; however, we generally conduct a comprehensive annual portfolio review with our clients during the annual meeting. Additional meetings and reviews may occur whenever needed.',
      ],
      isActive: true,
   },
   {
      id: 7,
      question: 'What does "discretion" mean?',
      answer: [
         'An investment advisor may be given investment discretion by you to decide which securities to purchase, sell and retain in your portfolio without having to verbally confirm each transaction with you. Discretion is important for us to make timely investment strategy changes.',
      ],
      isActive: true,
   },
   {
      id: 8,
      question:
         'If I become a client, do I need to stay a client for a certain period of time?',
      answer: [
         'No, the relationship between a client and SPC can be severed at any time upon written notice.',
      ],
      isActive: true,
   },
   {
      id: 9,
      question: 'Will you meet with me even if I have no assets?',
      answer: [
         'Yes, provided we are able to help with your needs and goals and you are committed to helping to improve your financial situation.',
      ],
      isActive: true,
   },
   {
      id: 10,
      question: 'Do you provide any additional services?',
      answer: [
         'Many of our advisors are insurance licensed and are able to help implement life, disability and long-term care insurance plans. In addition, we offer tax preparation and planning services for individuals, families, businesses, estates and trusts through our related firm, Sella & Martinic, LLC*.',
      ],
      isActive: true,
   },
   {
      id: 11,
      question: 'Do you provide legal services?',
      answer: [
         'No, we are not attorneys and do not provide legal advice. We can work closely with your attorney to help implement your plan.',
      ],
      isActive: true,
   },
   {
      id: 12,
      question: 'Do you charge for an initial introductory fact-find meeting?',
      answer: [
         'Our comprehensive initial consultation meetings are complimentary and there is no obligation to move forward.',
      ],
      isActive: true,
   },
   {
      id: 13,
      question: 'What is Insights by SPC Financial®?',
      answer: [
         'Insights by SPC Financial® is an online financial hub for all of your financial accounts. By using the system, you are able to aggregate your various financial accounts to get a complete picture of your financial life at any point in time. This tool provides an online vault to store important documents, and it can download spending data to help manage your personal spending and income plans.',
      ],
      isActive: true,
   },
   {
      id: 14,
      question: 'Is client information secure?',
      answer: [
         'The protection of private, personally-identifiable client information is of the utmost importance to SPC. SPC, its employees, and third party vendors work diligently to ensure that client-sensitive information is protected, encrypted and securely stored.',
      ],
      isActive: true,
   },
   {
      id: 15,
      question: 'What is your investment philosophy?',
      answer: [
         'For the past five decades, SPC has strived to understand our clients’ individualized investment goals and objectives to best meet their needs. We establish and allocate our accounts strategically and tactically generally utilizing low-cost, passive securities with a focus on tax-efficiency to help allow our clients’ portfolios to grow over time, while managing unforeseen downside risk.',
      ],
      isActive: true,
   },
   {
      id: 16,
      question: "What is your Advisor's best means of contact?",
      answer: [
         'Each client relationship is unique. You and your advisor will decide together how you would like to communicate to ensure that our services meet your needs.',
      ],
      isActive: true,
   },
   {
      id: 17,
      question:
         'How do I stop receiving paper confirmations and prospectuses in the mail?',
      answer: [
         'You can easily choose your document delivery preferences by letting your financial advisor know or by logging into your online account and making the changes yourself. Clients can continue to receive monthly statements in the mail even if they elect to receive confirmations and prospectuses electronically.',
      ],
      isActive: true,
   },
   {
      id: 18,
      question: "Why can't I send you an email with the requested information?",
      answer: [
         'Given the increasing risk of identity theft, we strongly recommend that any personally identifiable information (PII) be sent either by fax, regular mail, encrypted e-mail or through our portal.',
      ],
      isActive: true,
   },
   {
      id: 19,
      question:
         'I would like to be able to contribute to my accounts via online transfer as my cash flow permits. How can I set this up?',
      answer: [
         'Please let your Advisor know and they would be happy to establish or facilitate this transfer.',
      ],
      isActive: true,
   },
   {
      id: 20,
      question:
         'How long does it take from when I request monies from my portfolio to have them in my bank account?',
      answer: [
         'If there is cash available in your account, we strive to have the funds to you by the next business day. If you do not have sufficient cash available in your account and we need to sell investments, we ask clients to provide 5 business days’ notice. If funds are sent by check, please allow additional time for the check to be processed and delivered by the U.S. Postal Service.',
      ],
      isActive: true,
   },
   {
      id: 21,
      question: 'Are my retirement accounts protected from creditors?',
      answer: [
         'Creditor protection is subject to federal and state law. You should consult with your legal advisor regarding creditor protection strategies.',
      ],
      isActive: true,
   },
]

export default {
   faqs,
}
